<template>
  <section class="invitation-main">
    <div class="box" v-if="!invitationAccept">
      <h1>
        Você foi convidado pela empresa
        <strong v-if="invite.company_owner">{{ invite.company_owner.name }}</strong>
      </h1>
      <p v-if="invite.company_owner">
        Aceite o convite para visualizar os dados no vDash.
      </p>
      <button type="button" class="btn" @click="acceptInvite(token)">Aceitar convite</button>
    </div>

    <!-- === invitationAccept === -->
    <div class="box" v-else>
      <router-link to="/contas">
        <img class="box__close" src="~@/assets/images/icones/close.svg" alt="close" />
      </router-link>
      <h1>
        Convite para
        <strong v-if="invite.company_owner">{{ invite.company_owner.name }}</strong>
        aceito.
        <br />
        Agora você pode:
      </h1>
      <div class="box__columns">
        <div class="box__columns--column">
          <h2>Alterar a visualização dos dados em “Contas vinculadas” no menu superior direito.</h2>
          <figure>
            <IconAccountExample></IconAccountExample>
          </figure>
        </div>
        <div class="box__columns--column">
          <h2>
            Ver os dados comparativos em “Contas” no menu superior esquerdo.
          </h2>
          <figure>
            <IconMenuAccountExample></IconMenuAccountExample>
          </figure>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import { getValidationInvitation, acceptInvite } from "@/api/accounts";
import IconAccountExample from "@/components/icones/IconAccountExample.vue";
import IconMenuAccountExample from "@/components/icones/IconMenuAccountExample.vue";
export default {
  components: {
    IconAccountExample,
    IconMenuAccountExample,
  },
  data() {
    return {
      password: "",
      token: null,
    };
  },

  computed: {
    ...mapState({
      invite: state => state.accounts.invitationValidation,
      invitationAccept: state => state.accounts.invitationAccept,
    }),
  },
  methods: {
    getValidationInvitation,
    acceptInvite,
  },
  mounted() {
    this.token = this.$route.params.token;
    this.getValidationInvitation(this.token);
    this.$store.commit("accounts/SET_SHOW_ACCOUNTS", false);
  },
};
</script>

<style lang="scss" scoped>
.invitation-main {
  display: flex;
  justify-content: center;
  align-items: center;
  .box {
    padding: 0;
    background-color: #ffffff;
    width: 100%;
    max-width: 900px;
    height: 500px;
    background: #fff;
    box-shadow: 0px 3px 6px rgba(195, 208, 224, 0.16);
    margin: auto;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    position: relative;
    @media (max-width: 700px) {
      max-width: 100%;
    }
    h1 {
      font-weight: normal;
      margin-bottom: 25px;
      font-size: 24px;
      text-align: center;
    }
    p {
      font-size: 16px;
      color: #707070;
      max-width: 445px;
      text-align: center;
    }
    .btn {
      width: 100%;
      max-width: 335px;
      height: 51px;
      border-radius: 6px;
      background: #2c2c2c;
      color: $white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 60px;
      font-weight: 600;
      &:hover,
      &:focus {
        background: $red;
        color: $white;
        outline: none;
        box-shadow: none;
      }
    }
    &__columns {
      display: flex;
      margin-top: 35px;
      h2 {
        font-size: 16px;
        margin-bottom: 20px;
        display: block;
        min-height: 70px;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
      }
      &--column {
        padding: 48px 0;
        text-align: center;
        max-width: 365px;
        &:first-child {
          border-right: 1px solid #e4e4e4;
          padding-right: 40px;
        }
        &:last-child {
          padding-left: 40px;
        }
        figure {
          height: 55px;
          display: flex;
          align-content: center;
          align-items: center;
          justify-content: center;
        }
      }
    }
    &__close {
      position: absolute;
      top: 30px;
      right: 30px;
    }
  }
}
</style>
